import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse  } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
    
    constructor(private router:Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.accessToken) {
            request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.accessToken}`
                }
            });
        }

        //return next.handle(request);
        return next.handle(request);
    }
}




// import { Injectable } from '@angular/core';
// import { Router } from '@angular/router';
// import {
//   HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse
// } from '@angular/common/http';

// import { Observable } from 'rxjs';
// // import 'rxjs/add/operator/catch';
// // import 'rxjs/add/observable/throw';
// // import 'rxjs/add/operator/do';
// import { HttpErrorResponse } from '@angular/common/http';
// import { RequestOptions } from '@angular/http';
// import { HttpHeaders } from '@angular/common/http';

// @Injectable()
// export class MyInterceptor implements HttpInterceptor {
//   private userid: any;
//   private token: any;

//   constructor(private router: Router) { }
//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     // this.blockUI.start("Loading..")
//     //this.setuser();
//     this.token = 'gfhsfjdafcjshdcjhsdgcjhsdgcjhsdgcjhgdwsjhgcjhdsg';
//     if (this.token) {
//       const accessReq = req.clone({
//         headers: req.headers.set('authorization', 'bearer ' + this.token).set('user', this.userid)
//       });
//       return next.handle(accessReq);
//     } else {
//       return next.handle(req);
//     }
//   }

// //   setuser() {
// //     if (this.router.url.split('/')[1] === 'admin') {
// //       this.userid = localStorage.getItem('admin');
// //       this.token = localStorage.getItem('admin_token');
// //     }
// //     if (this.router.url.split('/')[1] === 'builder') {
// //       this.userid = localStorage.getItem('user');
// //       this.token = localStorage.getItem('token');
// //     }
// //     if (this.router.url.split('/')[1] === 'contractor') {
// //       this.userid = localStorage.getItem('contractor');
// //       this.token = localStorage.getItem('token');
// //     }
// //     if (this.router.url.split('/')[1] === 'customer') {
// //       this.userid = localStorage.getItem('customer');
// //       this.token = localStorage.getItem('token');
// //     }
// //     if (this.router.url.split('/')[1] === 'builder' && localStorage.getItem('staff')) {
// //       this.userid = localStorage.getItem('staff');
// //       this.token = localStorage.getItem('token');
// //     }
// //   }
// }