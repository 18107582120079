import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  [x: string]: any;
private base_Url = environment.apiUrl;

private commonApi ="api/common/";

private accountApi ="api/account/";
  constructor(  private _http : HttpClient) { }


  setCurrentUser(userdata){
    localStorage.setItem('currentUser', JSON.stringify(userdata));
    // localStorage.setItem('currentUser', userdata);
  }
  setUserDetails(userdata){
    localStorage.setItem('userDetails', JSON.stringify(userdata));
  }
  getUserLoggedIn() {
    const userdata = localStorage.getItem('currentUser');
    if (userdata && userdata !== '') {
      return true;
    } else {
      return false;
    }
  }


  GetNextId(Model){
    const Url = this.base_Url +  this.commonApi + `GetNextId`;
    return this._http.post(Url, Model).pipe()
  }

  GetUserList(Model){
    const Url = this.base_Url +  this.commonApi + `GetUserList`;
    return this._http.post(Url, Model).pipe()
  }
  
  GetOldQuantity(Model){
    const Url = this.base_Url +  this.commonApi + `GetOldQuantity`;
    return this._http.post(Url, Model).pipe()
  }


  GetUserPermissionData(Model){
    const Url = this.base_Url +  this.accountApi + `GetUserPermissionData`;
    return this._http.post(Url, Model).pipe()
  }


  
  
  UpdatePermissions(Model){
    const Url = this.base_Url +  this.commonApi + `UpdatePermissions`;
    return this._http.post(Url, Model).pipe()
  }


  getUserTypes() {
    const Url = this.base_Url + '/api/Common/GetUserTypes';
    return this._http.get(Url).pipe()
  }

  getStateList() {
    const Url = this.base_Url + '/api/Common/GetStateList';
    return this._http.get(Url).pipe()
  }
  GetCityList() {
    return this._http.get(this.base_Url + this.commonApi + `GetCityList`).pipe()
  }
  GetCitiesWorkDetails(){
    return this._http.get(this.base_Url+this.commonApi+'GetCitiesWorkDetails').pipe()
  }
  GetManagerList() {
    return this._http.get(this.base_Url + this.commonApi + `GetManagerList`).pipe()
  }

  GetManagerListByCity(Model) {
    const Url = this.base_Url +  this.commonApi + `GetManagerListByCity`;
    return this._http.post(Url, Model).pipe()
  }
  GetSupervisorList(Model){
    const Url = this.base_Url +  this.commonApi + `GetSupervisorsByMgrId`;
    return this._http.post(Url, Model).pipe()
  }


  getStreetTypes() {
    const Url = this.base_Url + '/api/Common/GetStreetTypes';
    return this._http.get(Url).pipe()
  }
 

  
  ResetPassword(Model){
    const Url = this.base_Url +  this.accountApi + `ResetPassword`;
    return this._http.post(Url, Model).pipe()
  }

  
  GetUserContactDetails(Model){
    const Url = this.base_Url +  this.accountApi + `GetUserContactDetails`;
    return this._http.post(Url, Model).pipe()
  }


  UserTransfer(Model){
    const Url = this.base_Url +  this.commonApi + `UserTransfer`;
    return this._http.post(Url, Model).pipe()
  }



}
