import { MMAuthGuard } from './shared-module/AuthGuard/mmauth.guard';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MMNoAuthGuard } from './shared-module/noAuthGuard/mmno-auth.guard';
console.log("Hello");
 export const routes: Routes = [
  // {
  //   path: 'forgot-reset',
  //   canActivate: [MMNoAuthGuard],
  //   // loadChildren: './ApplicationModules/home/home.module#HomeModule'
  //   loadChildren: './applicationModules/forgot-reset/forgot-reset.module#ForgotResetModule'
  // },
  {
    path: 'register',
    canActivate: [MMNoAuthGuard],
    // loadChildren: './ApplicationModules/home/home.module#HomeModule'
    loadChildren: './applicationModules/registerModule/register.module#RegisterModule'
  },
  {
    path: 'dashboard',
     canActivate: [MMAuthGuard],
    // loadChildren: './ApplicationModules/home/home.module#HomeModule'
    loadChildren: './applicationModules/admin-module/admin-module.module#AdminModuleModule'
  },
  {
    path: 'login',
    canActivate: [MMNoAuthGuard],
    // loadChildren: './ApplicationModules/home/home.module#HomeModule'
    loadChildren: './applicationModules/loginModule/login-module.module#LoginModuleModule'
  },
  {
    path: '',
    canActivate: [MMNoAuthGuard],
    // loadChildren: './ApplicationModules/home/home.module#HomeModule'
    loadChildren: './applicationModules/admin-module/admin-module.module#AdminModuleModule'
    // loadChildren: './applicationModules/loginModule/login-module.module#LoginModuleModule'
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
