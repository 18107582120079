import { CommonService } from './../../Services/common/common.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MMAuthGuard implements CanActivate {
  constructor(private common: CommonService, private _router: Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(this.common.getUserLoggedIn()) {
        return true;
      } else{
        this._router.navigate(['login']);
        return false;
      }
      //return true;
  }
}
