import { MyInterceptor } from './shared-module/authInterceptor/auth.interceptor';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppComponent } from './app.component';
import { MMAuthGuard } from './shared-module/AuthGuard/mmauth.guard';
import { MMNoAuthGuard } from './shared-module/noAuthGuard/mmno-auth.guard';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppRoutingModule, routes } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxMyDatePickerModule } from 'ngx-mydatepicker';
import { HighchartsChartModule } from 'highcharts-angular';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { MomentModule } from 'ngx-moment';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    CommonModule,   
    AppRoutingModule,
    BrowserModule,
     NgxSpinnerModule,
     NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()  ,
   NgxMyDatePickerModule.forRoot(),
   NgxSmartModalModule.forRoot(),
   HighchartsChartModule,
   MomentModule.forRoot({
    relativeTimeThresholdOptions: {
      'm': 59
    }
  })
  //  ModalDialogModule.forRoot()
  ],
  providers: [
    MMAuthGuard,
    MMNoAuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass:MyInterceptor,
      multi: true
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
